import React, {useRef, useState} from "react";
import '../css/style.css';
import '../css/visitCard.css';
import instaDark from "../img/instaDark.svg"
import PxDark from "../img/500px1.svg"
import WhatsappDark from "../img/whatsappDark.svg"
import Whatsapp from "../img/whatsapp.svg"
import * as emailjs from "@emailjs/browser";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDarkMode} from "./DarkModeContext";

const Contact = () => {
    const form = useRef();

    const handleNameInput = (event, msg) => {
        const nameInput = event.target;

        if (nameInput.validity.valueMissing) {
            nameInput.setCustomValidity(msg);
        } else {
            nameInput.setCustomValidity("");
        }
    };

    const handleEmailInput = (event, msg) => {
        const nameInput = event.target;
        const email = nameInput.value;

        if (nameInput.validity.valueMissing) {
            nameInput.setCustomValidity(msg);
        } else if (!email.includes("@") || email.split("@")[1].indexOf(".") === -1) {
            nameInput.setCustomValidity("Please include \"@\" in the email address. Missing \"@\" symbol in " + nameInput.value);
        } else {
            const atIndex = email.indexOf("@");
            const lastDotIndex = email.lastIndexOf(".");
            if (lastDotIndex < atIndex) {
                nameInput.setCustomValidity(
                    "The location of the '.' is incorrect in the email address"
                );
            } else {
                nameInput.setCustomValidity("");
            }
        }
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const isFormValid = form.current.checkValidity();
        if (isFormValid) {
            emailjs.sendForm('service_o6gpwdi', 'template_kw1utc2', form.current, 'dNYqRM5CEOYY4mob7')
                .then(() => {
                    const notify = () => toast('Your message has been sent');
                    notify();
                    document.getElementById("name").value = "";
                    document.getElementById("email").value = "";
                    document.getElementById("message").value = "";
                    // console.log(data);
                })
                .catch(() => {
                    const notify = () => toast('Error, the message was not sent!');
                    notify();
                    //console.error(error);
                });
        }
    };
    const {isDarkMode} = useDarkMode();
    const Camera = () => (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="55px" height="55px" viewBox="0 0 1000.000000 1000.000000"
             preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,1000.000000) scale(0.100000,-0.100000)"
               fill={isDarkMode ? '#ffffff' : '#212121'} stroke="none">
                <path d="M3280 8125 c-129 -388 -158 -467 -175 -470 -11 -3 -454 -59 -985
                -125 -531 -66 -987 -125 -1015 -131 -27 -6 -88 -29 -134 -51 -158 -75 -279
                -225 -328 -408 -17 -61 -18 -205 -18 -2450 0 -2242 1 -2389 18 -2450 64 -240
                259 -423 492 -465 120 -21 7610 -21 7730 0 233 42 428 225 492 465 17 61 18
                208 18 2450 0 2242 -1 2389 -18 2450 -59 220 -234 400 -442 454 -33 8 -496 69
                -1030 136 -533 66 -979 123 -990 125 -17 3 -45 80 -175 470 l-155 465 -1565 0
                -1565 0 -155 -465z m1964 -1580 c426 -56 801 -246 1101 -556 208 -215 344
                -439 435 -717 63 -194 82 -308 87 -538 6 -226 -6 -336 -58 -532 -125 -475
                -448 -895 -878 -1141 -650 -372 -1455 -321 -2056 130 -347 260 -593 631 -694
                1048 -44 181 -54 285 -48 509 4 158 10 221 31 318 105 495 392 914 821 1197
                215 141 496 245 762 282 134 18 359 18 497 0z m3346 -295 l0 -310 -413 0
                c-340 0 -422 3 -464 15 -64 19 -145 90 -181 159 -26 49 -27 57 -30 249 l-4
                197 546 0 546 0 0 -310z"/>
                <path d="M1092 8048 l3 -233 623 -3 622 -2 0 235 0 235 -625 0 -625 0 2 -232z"/>
            </g>
        </svg>
    );
    const [screenWidth] = useState(window.innerWidth);

    return (
        <div
            className={`relative overflow-hidden font-nunito ${isDarkMode ? 'bg-black1' : 'bg-white1'}`}
            id="Contact">


            <div className="containerCard" style={{
                paddingTop: screenWidth < 767 ? "17rem" : "18rem",
                paddingBottom: screenWidth < 767 ? "19rem" : "21rem"
            }}>
                <div className="allcard lastCard"></div>
                <div className="allcard card">
                </div>
                <div className="allcard firstCard">
                    <div className="allcard card-pink"></div>
                    <h1 className="textCard nameCard nonSelectalbe">ELIAS KARTAUX</h1>
                    <div className="textCard bar nonSelectalbe"/>
                    <p className="textCard titleCard nonSelectalbe">Photographer</p>
                    <div className="textCard cameraCard"><Camera/></div>
                    <div className="circleCard oneCard"></div>
                    <p className="textCard emailCard nonSelectalbe">
                        <button onClick={() => window.location.href = "mailto:ekartaux@gmail.com"}>ekartaux@gmail.com</button>
                    </p>
                    <p className="textCard telCard nonSelectalbe">
                        <button onClick={() => window.location.href = "tel:+972587474539"}>+972 58747 4539</button>
                    </p>
                </div>
            </div>
            <div>
                <p className="text-center text-black text-5xl">Contact me</p>
                <form ref={form} onSubmit={sendEmail}
                      className="from-indigo-200 p-8 flex flex-col gap-5 items-center">
                    <input
                        style={{maxWidth: "550px", color: "black"}}
                        className="p-2 w-full md:w-1/2 ring-1 ring-indigo-300 rounded-sm dark:bg-slate-800 dark:ring-0 dark:text-white input-contact"
                        id="name"
                        name="user_name"
                        type="text"
                        placeholder="Name"
                        maxLength="25"
                        required
                        onInvalid={(event) => handleNameInput(event, 'Please complete this field')}
                    />
                    <input
                        style={{maxWidth: "550px", color: "black"}}
                        className="p-2 w-full md:w-1/2 ring-1 ring-indigo-300 rounded-sm dark:bg-slate-800 dark:ring-0 dark:text-white input-contact"
                        id="email"
                        name="user_email"
                        type="email"
                        placeholder="Email"
                        maxLength="30"
                        required
                        onInvalid={(event) => handleEmailInput(event, 'Please enter a valid email address')}
                    />
                    <textarea
                        style={{maxWidth: "550px", color: "black"}}
                        className="p-2 p-2 w-full md:w-1/2 ring-1 ring-indigo-300 rounded-sm dark:bg-slate-800 dark:ring-0 dark:text-white input-contact"
                        maxLength="800"
                        id="message"
                        name="message"
                        cols="30"
                        rows="7"
                        type="text"
                        placeholder="Message . . ."
                        required
                        onInvalid={(event) => handleNameInput(event, 'Please complete this field')}
                    ></textarea>
                    <button
                        className="w-1/3 text-black font-medium mt-5 py-2contact rounded-md cursor-pointer"
                        type="submit">Submit
                    </button>
                </form>

                {/*insta / 500px*/}
                <div className="from-indigo-200 flex" style={{justifyContent: "center", paddingBottom: "2rem"}}>
                    <button className="flex-col btn-contact items-center"
                            onClick={() => window.location.href = "https://www.instagram.com/_e.k_photos/"}>
                        <img src={instaDark} alt="instagram" title="instagram" height="50px"/>
                    </button>
                    <div style={{width: "1.5rem"}}></div>

                    <button className="flex-col btn-contact items-center"
                            onClick={() => window.location.href = "https://500px.com/p/_eliask_?view=photos"}>
                        <img src={PxDark} alt="500px" title="500px" height="50px"/>
                    </button>

                    <div style={{width: "1.5rem"}}></div>
                    <button className="flex-col btn-contact items-center"
                            onClick={() => window.location.href = "https://wa.me/+972587474539"}>
                        <img src={isDarkMode ? WhatsappDark : Whatsapp} alt="Whatsapp" title="Whatsapp" height="45px"/>
                    </button>
                </div>
                <p className="from-indigo-200 text-center text-black copyright">© Copyright Elias Kartaux 2024</p>
            </div>
            {/*toast*/}
            <div>
                <ToastContainer/>
            </div>
        </div>
    );
}

export default Contact;
