import React, {useEffect, useState, memo} from "react";
import {FaArrowCircleUp} from 'react-icons/fa';
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Nature from "./components/Nature";
import Portraits from './components/Portraits';
import Urban from "./components/Urban";
import Architecture from "./components/Architecture";
import Events from "./components/Events";
import Contact from "./components/Contact";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import "../src/css/home.css"
import {DarkModeProvider} from "./components/DarkModeContext";
function PageNotFound() {
    return (
        <div className="from-indigo-200 text-center py-30" style={{height : "100vh"}}>
            <p className="text-white text-black">Page not found !</p>
        </div>
    );
}

function ScrollToTop() {
    const [show, setShow] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const handleScroll = () => {
        if (window.pageYOffset > 300) {
            setShow(true);
        } else {
            setShow(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleClick = () => {
        setIsAnimating(true);
        window.scrollTo({top: 0, behavior: "smooth"});
        setTimeout(() => setIsAnimating(false), 500);
    };

    return (
        <>
            {show && (
                <div
                    className={`scroll-to-top ${isAnimating ? "slide-out" : "slide-in"}`}
                    onClick={handleClick}
                >
                    <FaArrowCircleUp/>
                </div>
            )}
        </>
    );
}


class App extends React.Component {
    // componentDidMount() {
    //     const body = document.querySelector('body');
    //     // console.log(body);
    //
    // }

    render() {
        function ExternalRedirect() {
            window.location.href = 'https://dashboardelias.vercel.app/home';
            return null;
        }
        return (
            <DarkModeProvider>
                <BrowserRouter>
                    <ScrollToTop/>
                    <Navbar/>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/nature" element={<Nature/>}/>
                        <Route path="/portraits" element={<Portraits/>}/>
                        <Route path="/urban" element={<Urban/>}/>
                        <Route path="/architecture" element={<Architecture/>}/>
                        <Route path="/events" element={<Events/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="*" element={<PageNotFound/>}/>
                        <Route path="/login" element={<ExternalRedirect />} />
                    </Routes>
                </BrowserRouter>
            </DarkModeProvider>
        );
    }
}

///////////A METTRE A LA FIN ///
// class App extends React.Component {
//     componentDidMount() {
//         const body = document.querySelector('body');
//         if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
//             body.classList.add('dark');
//         } else {
//             body.classList.add('white');
//         }
//
//         document.addEventListener('contextmenu', this.handleContextMenu);
// document.addEventListener('keydown', function(e) {
//     // Interdit la combinaison de touches Ctrl + U
//     if (e.ctrlKey && e.key === 'u') {
//         e.preventDefault();
//     }
// });
//     }
//
//     componentWillUnmount() {
//         document.removeEventListener('contextmenu', this.handleContextMenu);
//     }
//
//     handleContextMenu = (event) => {
//         event.preventDefault();
//     }
//
//     render() {
//         return (
//             <BrowserRouter>
//                 <Routes>
//                     <Route path="/" element={<Home/>}/>
//                     <Route path="/contact" element={<Contact/>}/>
//                     <Route path="/nature" element={<Nature/>}/>
//                     <Route path="*" element={<PageNotFound/>}/>
//                 </Routes>
//             </BrowserRouter>
//         )
//     }
// }


export default memo(App);
