// Importez les fonctions dont vous avez besoin depuis le SDK Firebase
import { initializeApp } from 'firebase/app';
import { getStorage, ref as storageRef } from 'firebase/storage';
import { getDatabase, ref as dbRef } from 'firebase/database';

const firebaseConfig = {
    projectId: "eliasphoto",
    storageBucket: "eliasphoto.appspot.com",
};

// Initialisation de l'application Firebase
const app = initializeApp(firebaseConfig);

const storage = getStorage(app);
const database = getDatabase(app);

export { app as firebase, storage, database, storageRef, dbRef };
