import React, { useState, useEffect } from "react";
import { useDarkMode } from "./DarkModeContext";
import "../firebase"
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import "../css/imageGallery.css"

const Gallery = ({imagePath}) => {
    const { isDarkMode } = useDarkMode();
    const [sortedImages, setSortedImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);

    // Composant pour l'animation de chargement
    const Rolling = () => (
        // Animation de chargement en SVG avec une autre apparence
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            style={{
                margin: "auto",
                background: isDarkMode ? "rgb(0, 0, 0)" : "rgb(255, 255, 255)",
                display: "block",
                shapeRendering: "auto",
            }}
            width="200px"
            height="200px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
        >
            <circle
                cx="50"
                cy="50"
                fill="none"
                stroke={isDarkMode ? "#ffffff" : "#000000"}
                strokeWidth="6"
                r="28"
                strokeDasharray="110 40"
                style={{
                    animation: "rotate 1s infinite",
                    transformOrigin: "50% 50%",
                    strokeLinecap: "round",
                }}
            />
            <style>
                {`
                @keyframes rotate {
                  0% { transform: rotate(0deg); }
                  100% { transform: rotate(360deg); }
                }
              `}
            </style>
        </svg>
    );

    // // Chargement des images depuis Firebase Storage
    // useEffect(() => {
    //     const fetchFirebaseImages = async () => {
    //         try {
    //             const storage = getStorage();
    //             const storageRef = ref(storage, imagePath);
    //             const imageList = await listAll(storageRef);
    //
    //             const filteredItems = imageList.items.filter(item => item.name !== "nothing");
    //
    //             const images = await Promise.all(
    //                 filteredItems.map(async (item) => {
    //                     const url = await getDownloadURL(item);
    //                     const img = new Image();
    //                     img.src = url;
    //
    //                     return new Promise((resolve, reject) => {
    //                         img.onload = () => {
    //                             resolve({
    //                                 src: url,
    //                                 width: img.width,
    //                                 height: img.height,
    //                             });
    //                         };
    //                         img.onerror = () => {
    //                             reject();
    //                         };
    //                     });
    //                 })
    //             );
    //
    //             const imagesByRatio = images.sort((a, b) => {
    //                 const ratioA = a.width / a.height;
    //                 const ratioB = b.width / b.height;
    //                 return ratioA - ratioB;
    //             });
    //
    //             const rows = [];
    //             let currentRow = [];
    //
    //             imagesByRatio.forEach((image, index) => {
    //                 currentRow.push(image);
    //
    //                 if (currentRow.length === 3 || index === imagesByRatio.length - 1) {
    //                     rows.push(currentRow);
    //                     currentRow = [];
    //                 }
    //             });
    //
    //
    //             setSortedImages(rows);
    //             setLoading(false);
    //         } catch (error) {
    //             console.error('Erreur lors du chargement des images depuis Firebase Storage :', error);
    //         }
    //     };
    //
    //     fetchFirebaseImages();
    // }, [imagePath]); // Ajout de imagePath comme dépendance du useEffect


    // Ouvrir l'image dans le modal
    const openImage = (index) => {
        setCurrentImageIndex(index);
        setShowModal(true);
    };

    // Fermer le modal
    const closeModal = () => {
        setShowModal(false);
    };

    // Aller à l'image précédente
    const goToPreviousImage = () => {
        const lastIndex = sortedImages.flat().length - 1;
        const prevIndex = currentImageIndex === 0 ? lastIndex : currentImageIndex - 1;
        setCurrentImageIndex(prevIndex);
    };


    // Aller à l'image suivante
    const goToNextImage = () => {
        if (currentImageIndex + 1 >= sortedImages.flat().length) {
            setCurrentImageIndex(0);
        } else {
            setCurrentImageIndex(currentImageIndex + 1);
        }
    };

    useEffect(() => {
        const fetchFirebaseImages = async () => {
            try {
                const storage = getStorage();
                const storageRef = ref(storage, imagePath);
                const imageList = await listAll(storageRef);

                const filteredItems = imageList.items.filter(item => item.name !== "nothing");

                const images = filteredItems.map((item) => {
                    // console.log(item.name)
                    return {
                        item,
                        src: null,
                        width: 0,
                        height: 0,
                        loading: true,
                    };
                });

                setSortedImages(images);
                setLoading(false);
            } catch (error) {
                console.error('Erreur lors du chargement des images depuis Firebase Storage :', error);
            }
        };

        fetchFirebaseImages();
    }, [imagePath]); // Ajout de imagePath comme dépendance du useEffect

    // Chargement des images une par une
    useEffect(() => {
        const loadNextImage = async () => {
            const nextImage = sortedImages.find((image) => image.loading);

            if (nextImage) {
                const url = await getDownloadURL(nextImage.item);
                const img = new Image();
                img.src = url;

                img.onload = () => {
                    nextImage.src = url;
                    nextImage.width = img.width;
                    nextImage.height = img.height;
                    nextImage.loading = false;

                    setSortedImages([...sortedImages]);
                };

                img.onerror = () => {
                    nextImage.loading = false;

                    setSortedImages([...sortedImages]);
                };
            }
        };

        loadNextImage();
    }, [sortedImages]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight') {
                goToNextImage();
            } else if (event.key === 'ArrowLeft') {
                goToPreviousImage();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            // N'oubliez pas de supprimer l'écouteur d'événements lorsque le composant est démonté
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentImageIndex, sortedImages]);



    // Affichage en cas de chargement
    if (loading) {
        return (
            <div className="font-nunito from-indigo-200 py-20">
                <div className="gallery">
                    <div className="gallery-image-container text-center loading">
                        <Rolling/>
                        <p className="text-center loading-message text-black"
                           style={{padding: "2rem"}}>
                            Loading . . .
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    // Affichage en cas d'absence d'images
    if (sortedImages.length === 0) {
        return (
            <div className="font-nunito from-indigo-200 py-20">
                <p className="text-center text-black">No image available</p>
            </div>
        );
    }

    return (
        <div className="font-nunito from-indigo-200">
            <div className="gallery">
                {sortedImages.map((image, index) => (
                    <div key={index} className="gallery-image-container" style={{cursor: "pointer"}}>
                        {image.loading ? (
                            <div className="text-center">
                                <Rolling/>
                                <p className="loading-message text-black" style={{padding: "2rem"}}>
                                    Loading . . .
                                </p>
                            </div>
                        ) : (
                            <img
                                src={image.src}
                                alt={image.src}
                                className="gallery-image"
                                onClick={() => openImage(index)}
                            />
                        )}
                    </div>
                ))}
            </div>
            {
                showModal && (
                    <div className="modal" onClick={closeModal}>
                        <button
                            className="modal-button modal-button-prev"
                            onClick={(e) => {
                                e.stopPropagation();
                                goToPreviousImage();
                            }}
                        >
                            &#x279C;
                        </button>
                        {sortedImages.flat()[currentImageIndex]?.src && (
                            <img
                                className="modal-content"
                                src={sortedImages.flat()[currentImageIndex].src}
                                alt={String(currentImageIndex)}
                            />
                        )}
                        <button
                            className="modal-button modal-button-next"
                            onClick={(e) => {
                                e.stopPropagation();
                                goToNextImage();
                            }}
                        >
                            &#10140;
                        </button>
                    </div>
                )}
            <p className="from-indigo-200 text-center text-black copyright">
                © Elias Kartaux 2024
            </p>
        </div>
    );
};

export default Gallery;
