import React, {useEffect, useState} from "react";
import "../css/home.css"
import "../css/style.css"
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Gallery from "./ImageGallery";
import {useDarkMode} from "./DarkModeContext";
import {getDownloadURL, getStorage, listAll, ref} from "firebase/storage";

const Home = () => {

    const [currentSlide, setCurrentSlide] = useState(0); // Initialize to 0
    const { isDarkMode } = useDarkMode();
    const [sortedImages, setSortedImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handlePrevClick = () => {
        setCurrentSlide((currentSlide - 1 + sortedImages.length) % sortedImages.length);
    };

    const handleNextClick = () => {
        setCurrentSlide((currentSlide + 1) % sortedImages.length);
    };

    const settings = {
        preventMovementUntilSwipeScrollTolerance: true,
        swipeScrollTolerance: 3000,
        transitionTime: 790,
        showArrows: false,
        showStatus: false,
        showIndicators: false,
        infiniteLoop: true,
        autoPlay: true,
        showThumbs: false,
        selectedItem: currentSlide,
        onChange: setCurrentSlide,
        useKeyboardArrows: true,
        stopOnHover: false,
        rewind: true,
        autoReverse: true,
        swipeable: true,
        emulateTouch: true,
    };

// Load images from Firebase

    useEffect(() => {
        const importCarouselImages = async () => {
            try {
                const storage = getStorage();
                const storageRef = ref(storage, '/Caroussel');
                const imageList = await listAll(storageRef);

                const filteredItems = imageList.items.filter(item => item.name !== "nothing");

                const data = await Promise.all(
                    filteredItems.map(async (item) => {
                        const url = await getDownloadURL(item);
                        const img = new Image();
                        img.src = url;

                        return new Promise((resolve, reject) => {
                            img.onload = () => {
                                resolve({
                                    image: url,
                                    caption: `Slide ${filteredItems.indexOf(item) + 1}`,
                                });
                            };
                            img.onerror = () => {
                                reject();
                            };
                        });
                    })
                );

                setSortedImages(data);
                setIsLoading(false); // Déplacez cette ligne ici
            } catch (error) {
                console.error('Erreur lors du chargement des images depuis Firebase Storage :', error);
            }
        };
        importCarouselImages();
    }, []);

    const [screenWidth] = useState(window.innerWidth);

    const Rolling = () => (
        // Animation de chargement en SVG avec une autre apparence
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            style={{
                margin: "auto",
                background: isDarkMode ? "rgb(0, 0, 0)" : "rgb(255, 255, 255)",
                display: "block",
                shapeRendering: "auto",
            }}
            width="200px"
            height="200px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
        >
            <circle
                cx="50"
                cy="50"
                fill="none"
                stroke={isDarkMode ? "#ffffff" : "#000000"}
                strokeWidth="6"
                r="28"
                strokeDasharray="110 40"
                style={{
                    animation: "rotate 1s infinite",
                    transformOrigin: "50% 50%",
                    strokeLinecap: "round",
                }}
            />
            <style>
                {`
                @keyframes rotate {
                  0% { transform: rotate(0deg); }
                  100% { transform: rotate(360deg); }
                }
              `}
            </style>
        </svg>
    );

    return (
        <div className={`font-nunito ${isDarkMode ? 'bg-black1' : 'bg-white1'}`}>
            <div id="Home" className="relative overflow-hidden" style={{height: "100Vh"}}>
                <div
                    style={{
                        position: 'relative',
                        paddingTop: screenWidth < 767 ? '0.7rem' : '0rem',
                        scale: screenWidth < 767 ? '100%' : '95%',
                    }}
                >
                    {isLoading ? (
                        <div className="text-center" style={{paddingTop: "5%"}}>
                            <Rolling/>
                            <p className="loading-message text-black" style={{paddingTop: "2rem"}}>
                                Loading . . .
                            </p>
                        </div>
                    ) : (
                        <div>
                            <Carousel {...settings}>
                                {sortedImages.map((slide, index) => (
                                    <div style={{cursor: 'default'}} key={index}>
                                        <img src={slide.image} alt={`slide${index}`}
                                             style={{paddingRight: '10px', paddingLeft: '10px'}}/>
                                    </div>
                                ))}
                            </Carousel>

                            <div>
                                <div className="arrowPrev" onClick={handlePrevClick}>
                                    &#x279C;
                                </div>
                                <div className="arrowNext" onClick={handleNextClick}>
                                    &#10140;
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/*image illustration*/}
            <div className={isDarkMode ? 'bg-black1' : 'bg-white1'}>
                <Gallery imagePath="Home" tabImage1={[]} tabImage2={[]} />
            </div>
        </div>
    );
};

export default Home;
