import React, { useEffect, useState } from "react";
import "../css/home.css";
import "../css/style.css";
import {useDarkMode} from "./DarkModeContext";

function App() {
    const { isDarkMode, setIsDarkMode } = useDarkMode();
    const [isRotated, setIsRotated] = useState(false);
    const handleIconClick = () => {
        setIsDarkMode(!isDarkMode);
        setIsRotated(true);

        // Reset isRotated to false after 0.7 seconds (animation duration)
        setTimeout(() => {
            setIsRotated(false);
        }, 700);
    };
    const Moonlight = ({ isDarkMode }) => (
        <svg
            width="24"
            height="24"
            style={{ cursor: 'pointer' }} // Add cursor style to indicate it's clickable
            viewBox="0 0 24 24"
        >
            <g fill={isDarkMode ? '#ffffff' : '#212121'}>
                <path d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M12,20.5 L12,3.5 C16.6944204,3.5 20.5,7.30557963 20.5,12 C20.5,16.6944204 16.6944204,20.5 12,20.5 Z"></path>
            </g>
            {/* Add the animateTransform element to apply the rotation */}
            {isRotated && (
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="180 0 0" // Rotate from 0 degrees around the origin (0, 0)
                    to="360 0 0" // Rotate to 360 degrees around the origin (0, 0)
                    dur="0.7s" // Duration of the animation in seconds
                    repeatCount="1" // Number of times to repeat the animation (1 in this case)
                    fill="freeze" // Keeps the final state of the animation after it finishes
                />
            )}
        </svg>
    );


    const [padding, setPadding] = useState("20px 10px");
    const [titleSizeTop, setTitleSizeTop] = useState("110px");
    const [titleSizeBottom, setTitleSizeBottom] = useState("17px");
    const [paddingTitleTop, setpaddingTitleTop] = useState("1rem");
    const [paddingTitlebottom, setPaddingTitlebottom] = useState("0rem");
    const [marginMode, setMarginMode] = useState("10rem");
    const [paddingTopCategories, setpaddingTopCategories] = useState("5.5rem");
    const [paddingBottomCategories, setpaddingBottomCategories] = useState("0rem");
    const [titleVisibility, setTitleVisibility] = useState("visible");
    const [isOpen, setIsOpen] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const handleScroll = () => {
        const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        if (scrollTop > 10) {
            setPadding("0px 0px");
            setTitleVisibility("hidden");
            setTitleSizeTop("0rem");
            setTitleSizeBottom("0rem");
            // setpaddingTitleTop("0rem");
            // setPaddingTitlebottom("0rem");
            setpaddingTopCategories("0rem");
            setpaddingBottomCategories(screenWidth < 950 ? "1rem" : "1rem");
        } else {
            setTitleVisibility("visible");
            setTitleSizeTop("110px");
            setTitleSizeBottom("17px");
            setpaddingTitleTop("1rem");
            setPadding("20px 10px");
            setPaddingTitlebottom("0rem");
            setPaddingTitlebottom("0rem");
            setMarginMode("10rem");
            setpaddingTopCategories("5.5rem");
            setpaddingBottomCategories("0rem");
        }
    };

    setTimeout(() => {
        // const textElements = document.querySelectorAll('.text-top, .text-bottom');
        // textElements.forEach((element) => {
        //     element.classList.add('animate-text');
        // });
        if (screenWidth < 950) {
            document.querySelector('.text-top').classList.add('animate-title-top-mobile')
            document.querySelector('.text-bottom').classList.add('animate-title-bottom-mobile')
        } else {
            document.querySelector('.text-top').classList.add('animate-title-top')
            document.querySelector('.text-bottom').classList.add('animate-title-bottom')
        }
    }, 150);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, );

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="font-nunito">
            <div className="h-1/2 lg:h-screen bg-gradient-to-t from-indigo-200 relative overflow-hidden">
                <div className="mx-auto h-1">
                    <nav id="navbar"
                        // style={{padding, background: isDarkMode ? "#1c1b1b" : "#faf9f9" , color : isDarkMode ? "white" : "black"}}
                         style={{padding}}
                         className={`w-full fixed ${isDarkMode ? 'bg-black' : 'bg-white'}`}>
                        <div className="flex items-center justify-center text-center md:justify-end align-items-center">
                            <div onClick={() => window.location.href = "/"} className="titre-elias">
                                <button>
                                    <div
                                        style={{
                                            visibility: titleVisibility,
                                            paddingTop: paddingTitleTop,
                                            paddingBottom: paddingTitlebottom,
                                            userSelect: "none",
                                        }}
                                    >
                                        <div className="overlay-container">
                                            <p className="text-top"
                                               style={{fontSize:titleSizeTop,userSelect: "none"}}>
                                                EK
                                            </p>
                                            <h1 className="text-bottom"
                                                style={{fontSize: titleSizeBottom, backgroundColor : isDarkMode  ? "#1c1b1b" : "#faf9f9",margin:0,userSelect: "none"}}>
                                                ELIAS KARTAUX
                                            </h1>
                                        </div>
                                    </div>
                                </button>
                            </div>

                            <button
                                className="text-center"
                                style={{display: "flex", position: "absolute",visibility: titleVisibility, marginTop: marginMode, userSelect: "none"}}>
                                <div className="moonlight" onClick={handleIconClick}>
                                    <Moonlight isDarkMode={isDarkMode}/>
                                </div>
                            </button>

                        </div>
                        <div>
                            <ul
                                className={`hidden flex text-center text-soustitre uppercase`}
                                style={{paddingTop: paddingTopCategories, paddingBottom: paddingBottomCategories }}
                            >
                                <li>
                                    <a className="selected" href="/" style={{color:isDarkMode  ? "#faf9f9" : "#1c1b1b"}}>Home</a>
                                </li>
                                <li>
                                    <a className="selected" href="/nature" style={{color:isDarkMode  ? "#faf9f9" : "#1c1b1b"}}>Nature</a>
                                </li>
                                <li>
                                    <a className="selected" href="/portraits" style={{color:isDarkMode  ? "#faf9f9" : "#1c1b1b"}}>Portraits</a>
                                </li>
                                <li>
                                    <a className="selected" href="/urban"
                                       style={{color: isDarkMode ? "#faf9f9" : "#1c1b1b"}}>Urban</a>
                                </li>
                                <li>
                                    <a className="selected" href="/architecture" style={{color:isDarkMode  ? "#faf9f9" : "#1c1b1b"}}>Architecture</a>
                                </li>
                                <li>
                                    <a className="selected" href="/events"
                                       style={{color: isDarkMode ? "#faf9f9" : "#1c1b1b"}}>Events</a>
                                </li>
                                <li>
                                    <a className="selected" href="/contact" style={{color:isDarkMode  ? "#faf9f9" : "#1c1b1b"}}>Contact</a>
                                </li>
                            </ul>
                            <div className="hamburger md:hidden" style={{paddingTop: paddingTopCategories, paddingBottom: paddingBottomCategories }}>
                                <button onClick={() => { document.querySelector("#menu").classList.toggle("hidden"); }}>
                                    <div id="hamburger" onClick={handleClick}>
                                        <div className={`humbuger ${isOpen ? "open" : ""}`}>
                                            <span className="colorhumberger"></span>
                                            <span className="colorhumberger"></span>
                                            <span className="colorhumberger"></span>
                                        </div>
                                    </div>
                                    <ul
                                        id="menu"
                                        className="hidden bg-sous-menu rounded-b-3xl space-y-4 text-black text-center"
                                        style={{ marginTop: "25px", position: "initial"}}
                                        onClick={handleClick}
                                    >
                                        <li>
                                            <a className="selected" href="/">Home</a>
                                        </li>
                                        <li>
                                            <a className="selected" href="/nature">Nature</a>
                                        </li>
                                        <li>
                                            <a className="selected" href="/portraits">Portraits</a>
                                        </li>
                                        <li>
                                            <a className="selected" href="/urban">Urban</a>
                                        </li>
                                        <li>
                                            <a className="selected" href="/architecture">Architecture</a>
                                        </li>
                                        <li>
                                            <a className="selected" href="/events">Events</a>
                                        </li>
                                        <li>
                                            <a className="selected" href="/contact">Contact</a>
                                        </li>
                                    </ul>
                                </button>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default App;
