// DarkModeContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';

const DarkModeContext = createContext();

export function useDarkMode() {
    return useContext(DarkModeContext);
}

export function DarkModeProvider({ children }) {
    const storedDarkMode = Cookies.get('isDarkMode');
    const systemPrefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    const [isDarkMode, setIsDarkMode] = useState(
        storedDarkMode === 'true' || (storedDarkMode === undefined && systemPrefersDark)
    );

    useEffect(() => {
        const body = document.querySelector('body');

        body.classList.toggle('dark', isDarkMode);
        body.classList.toggle('white', !isDarkMode);

        Cookies.set('isDarkMode', isDarkMode.toString(), { expires: 365 }); // Vous pouvez définir une expiration appropriée
    }, [isDarkMode]);

    // console.log(isDarkMode)
    return (
        <DarkModeContext.Provider value={{ isDarkMode, setIsDarkMode }}>
            {children}
        </DarkModeContext.Provider>
    );
}
