import React from 'react';
import Gallery from "./ImageGallery";
import {useDarkMode} from "./DarkModeContext";
function App() {
    const { isDarkMode } = useDarkMode();
    return (
        <div id="Portraits" className={`font-nunito ${isDarkMode ? 'bg-black1' : 'bg-white1'}`} style={{height : "100vh"}}>
            <Gallery imagePath="Portraits"/>
        </div>
    );
}

export default App
